<template>
  <h5>Meteoradministrasjon</h5>
  <div v-show="loading" class="loader">Laster inn....</div>
  <div
    class="table-responsive"
    style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px"
  >
    <table
      id="tableComponent"
      class="table table-light table-striped table-sm"
      style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px"
    >
      <thead>
        <tr>          
          <th style="text-align: center; padding: 0px 2px 0px 2px">
            <i class="fa-solid fa-image"></i
            ><span class="hideOnSmallScreens">&nbsp;Bilde</span>
          </th>
          <th
            style="text-align: right; min-width: 70px; padding: 0px 2px 0px 2px"
            @click="sort('date')"
          >
            <i class="fa-solid fa-sort fa-xs"></i>&nbsp;<br />
            <i class="fa fa-clock"></i>
            <span class="hideOnSmallScreens">&nbsp;Tidspunkt</span>
          </th>
          <th
            style="text-align: left; padding: 0px 2px 0px 2px"
            @click="sort('crossbearing')"
          >
            &nbsp;<i class="fa-solid fa-sort fa-xs"></i><br />
            <i class="fa-solid fa-crosshairs"></i>
            <span class="hideOnSmallScreens">&nbsp;Krysspeilet</span>
          </th>
          <th style="text-align: left; padding: 0px 2px 0px 2px">
            <i class="fa-solid fa-video"></i
            ><span class="hideOnSmallScreens">&nbsp;Stasjon</span>
          </th>
          <th
            style="text-align: right; padding: 0px 2px 0px 2px"
            @click="sort('ratings')"
          >
            <i class="fa-solid fa-sort fa-xs"></i>&nbsp;<br />
            <i class="fa-solid fa-thumbs-up"></i
            ><span class="hideOnSmallScreens">&nbsp;Vurderinger</span>
          </th>
          <th style="min-width: 38px; text-align: center; padding: 0px 2px 0px 2px">
            <i class="fa-solid fa-square-check"></i
            ><span class="hideOnSmallScreens">&nbsp;Klassifisering</span>
          </th>          
        </tr>
      </thead>
      <tbody class="smallerOnSmallScreens">
        <!-- Loop through the list get the each meteor data -->
        <tr v-for="(meteor, index) in Meteors" :key="index">       
         <td @click="pickMeteor(meteor, index)" style="text-align: center"> 
         <img
                  class="img-fluid"
                  style="max-height: 100px; width: auto"
                  :src="
                    'https://ildkule.net/data/' +
                    meteor.datetimetag.substring(0, 8) +
                    '/' +
                    meteor.datetimetag.substring(8) +
                    '/thumbnail.jpg'
                  "
                  alt="Meteorbilde"
                /> <br/>
            <a :href="'#teamModal' + index" v-b-modal="'teamModal' + index">
              Meteor ID:{{ meteor["id"] }}</a
            >
            <b-modal :id="'teamModal' + index">
              <MeteorComponent :meteor="meteor" :meteorData="this.currentMeteorData" />
            </b-modal>
          </td>
             <!-- <td style="text-align: center; padding: 2px 2px 2px 2px">              
              </td> -->
          <td style="text-align: right">
            {{ meteor["date"].split(" ")[0] }}<br />{{
              meteor["date"].split(" ")[1]
            }}
          </td>
          <td>{{ meteor["camera_confirmed"] != 0 ? "Ja" : "Nei" }}</td>
          <td>Harestua<br />Larvik<br />Kristiansand</td>
          <td style="text-align: right">
            {{ meteor["positive_ratings"] }}&nbsp;<i
              class="fa-solid fa-thumbs-up"
            ></i
            ><br />{{ meteor["negative_ratings"] }}&nbsp;<i
              class="fa-solid fa-thumbs-down"
            ></i>
          </td>
          <td
            style="
              font-size: 1rem;
              text-align: center;
              padding-left: 0px;
              padding-right: 0px;
            "
          >
            <form>
              <div class="form-group">
                <input
                  type="radio"
                  class="radio"
                  id="confirmed"
                  value="1"
                  v-model="meteor.user_confirmed"
                />&nbsp;
                <label for="confirmed">
                  <i style="color: #2dc937" class="fa-solid fa-square-check"></i
                ></label>
                <br />

                <input
                  type="radio"
                  class="radio"
                  id="confirmed"
                  value="0"
                  v-model="meteor.user_confirmed"
                />&nbsp;
                <label for="confirmed"
                  ><i
                    style="color: #cc3232"
                    class="fa-solid fa-square-xmark"
                  ></i
                ></label>
                <br />

                <input
                  type="radio"
                  class="radio"
                  id="confirmed"
                  value="-1"
                  v-model="meteor.user_confirmed"
                />&nbsp;
                <label for="confirmed">
                  <i style="color: #e7b416" class="fa-solid fa-square"></i
                ></label>
                <br />
                <b-button
                  type="submit"
                  size="sm"                  
                  @click="updateMeteor"                  
                > <span  class="smallerOnSmallScreens">
                  Oppd.
                  </span>
                </b-button>
              </div>
            </form>
            <p>{{ message }}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Start button for loading in more results-->
    <div
      v-if="this.Meteors.length > 0 && this.perPage <= this.Meteors.length"
      class="col-xs-1 mb-2"
      align="center"
      style="padding-bottom: 5rem"
    >
      <button class="btn btn-secondary" @click="loadMore">
        Last inn flere
      </button>
    </div>
    <!-- End button for loading in more results-->

    <!--<b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      @page-click="onPageChanged"
    />-->
  </div>
</template>

<script>
import MeteorDataService from "../../services/meteor.service";
import MeteorComponent from "../../components/MeteorComponent.vue";
export default {
  name: "Meteors-list",
  components: {
    MeteorComponent,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 10,
      orderby: "date",
      order: "asc",
      rows: 10,
      totalPages: 0,
      message: "",
      Meteors: [],
      meteorcolumns: [],
      currentMeteorData: null,
      count: 0,
      currentMeteor: null,
      currentIndex: -1,
      title: "",
      modalShow: false,
    };
  },
  methods: {
    pickMeteor(meteor, index){
      this.setActiveMeteor(meteor, index)      
      this.modalShow = !this.modalShow;
    },

    convertToLocalDate(dateString) {
      const d = new Date(dateString + " +0000");
      var curr_second = d.getSeconds();
      var curr_minute = d.getMinutes();
      var curr_hour = d.getHours();
      var curr_date = d.getDate();
      var curr_month = d.getMonth() + 1; //Months are zero based
      var curr_year = d.getFullYear();
      return (
        curr_year +
        "-" +
        curr_month.toString().padStart(2, "0") +
        "-" +
        curr_date.toString().padStart(2, "0") +
        " " +
        curr_hour.toString().padStart(2, "0") +
        ":" +
        curr_minute.toString().padStart(2, "0") +
        ":" +
        curr_second.toString().padStart(2, "0")
      );
    },

    updateMeteor() {
      MeteorDataService.update(this.currentMeteor.id, this.currentMeteor)
        .then((response) => {
          console.log(response.data);
          this.message = "Meteoren ble oppdatert: " + response.data.msg;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    sort(orderby) {
      console.log(orderby);
      switch (orderby) {
        case "date":
          if (this.orderby == "date") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "date";
          }
          break;
        case "crossbearing":
          if (this.orderby == "crossbearing") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "crossbearing";
          }
          break;
        case "ratings":
          if (this.orderby == "ratings") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "ratings";
          }
          break;
        default:
          this.orderby = "date";
          this.order = "desc";
      }
      this.retrieveMeteors();
    },

    retrieveMeteors() {
      this.loading = true;
      MeteorDataService.getMeteorsPaginated(
        this.currentPage,
        this.perPage,
        this.orderby,
        this.order
      )
        .then((response) => {
          const { meteors, totalItems, totalPages } = response.data;
          this.meteorcolumns = Object.keys(meteors[0]);
          this.Meteors = meteors;
          this.rows = parseInt(totalItems);
          this.totalPages = parseInt(totalPages);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
      this.loading = false;
    },
    refreshList() {
      this.retrieveMeteors();
      this.currentMeteor = null;
      this.currentIndex = -1;
    },

    loadMore() {
      console.log(this.perPage);
      if (this.perPage >= this.Meteors.length + 1) return;
      this.perPage = this.perPage + 10;
      this.retrieveMeteors();
    },

    setActiveMeteor(Meteor, index) {
      this.loading = true;
      MeteorDataService.get(Meteor.id)
        .then((response) => {
          var meteorDataResponse = response.data;
          for (
            let index = 0;
            index < meteorDataResponse.observation_cam_data.length;
            ++index
          ) {
            meteorDataResponse.observation_cam_data[index].videourl =
              "https://ildkule.net/data/" +
              meteorDataResponse.datetimetag.substring(0, 8) +
              "/" +
              meteorDataResponse.datetimetag.substring(8) +
              "/" +
              meteorDataResponse.observation_cam_data[index].cam.station
                .station_name +
              "/" +
              meteorDataResponse.observation_cam_data[index].cam.cam_name +
              "/" +
              meteorDataResponse.observation_cam_data[index].cam.station
                .station_name +
              "-" +
              meteorDataResponse.datetimetag +
              "-gnomonic.mp4";
          }
          this.currentMeteorData = meteorDataResponse;
          this.currentMeteor = Meteor;
          this.currentIndex = Meteor ? index : -1;
          console.log(this.currentMeteorData.observation_cam_data);
        })
        .catch((e) => {
          console.log(e);
        });
      this.loading = false;

      this.currentMeteor = Meteor;
      this.currentIndex = Meteor ? index : -1;
    },
  },
  mounted() {
    this.loading = true;
    this.retrieveMeteors();
    this.loading = false;
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.table.b-table>thead>tr>th {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
} 

@media screen and (min-width: 0px) and (max-width: 850px) {
  .hideOnSmallScreens {
    display: none;
  } /* hide it on smaller screen */
}

@media screen and (min-width: 0px) and (max-width: 850px) {
  .smallerOnSmallScreens {
    font-size: 0.75rem;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>